<template>
	<div class="side"
		v-if="!program.not_header"
	>
		<div class="nav-list">
			<div class="nav-item"
				v-for="(item, index) in item_list"
				:key="index"
			>
				<div class="title">{{ item.title }}</div>
				<template
					v-for="(item2, index2) in item.list"
				>
					<div class="menu"
						
						v-if="item2.grade.indexOf(Number(user.basic.level)) > -1"
						:key="index2"
						:class=" { active: item2.to == $route.name }"
					>
					
						<router-link 
							v-if="item2.use"
							:to="{ name: item2.to}">{{ item2.name }}</router-link>
						<a 
							v-if="!item2.use"
							@click="none"
						>{{ item2.name }}</a>
							
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Side'
		,props: ['user', 'program']
		,data: function(){
			return {
				items: [
					{
						title: '하위 대리점 관리'
						,list: [
							{
								to: 'AgentSalesItem'
								,use: true
								,name: '하위 대리점 등록'
								,grade: [1, 2]
							}
							,{
								to: 'AgentSalesList'
								,name: '하위 대리점 목록'
								,use: true
								,grade: [1, 2]
							}
						]
						,grade: [1, 2]
					}
					,{
						title: '가맹점 관리'
						,list: [
							{
								to: 'MerchantsApplyItem'
								,name: '가맹점 등록'
								,use: true
								,grade: [1, 2, 3]
							}
							,{
								to: 'MerchantsApplyList'
								,name: '가맹점 신청 내역'
								,use: true
								,grade: [1, 2, 3]
							}
							,{
								to: 'FranchiseeList'
								,name: '가맹점 목록'
								,use: true
								,grade: [1, 2, 3]
							}
							,{
								to: 'MerchantsTerminalList'
								,use: true
								,name: '단말기 관리'
								,grade: [1, 2, 3]
							}
						]
						,grade: [1, 2, 3]
					}
					,{
						title: '정산 관리'
						,list: [
							{
								to: 'SettlementAgentList'
								,use: true
								,name: '하위 대리점 정산내역'
								,grade: [1, 2]
							}
							,
							{
								to: 'SettlementMerchantList'
								,use: true
								,name: '가맹점 정산 내역'
								,grade: [1, 2, 3]
							}
						]
						,grade: [1, 2, 3]
					}
					,{
						title: '결제 관리'
						,list: [
							{
								to: 'PaymentsHistory'
								,use: true
								,name: '결제 내역 조회'
								,grade: [1, 2, 3]
							}
						]
						,grade: [1, 2, 3]
					}
					,{
						title: '정보 관리'
						,list: [
							{
								to: 'AgentDetail'
								,use: true
								,name: '마이페이지'
								,grade: [1, 2, 3]
							}
							,
							{
								to: 'NoticeList'
								,use: true
								,name: '공지사항'
								,grade: [1, 2, 3]
							}
						]
						,grade: [1, 2, 3]
					}
				]
			}
		}
		,computed: {
			item_list: function(){
				let self = this
				
				return this.items.filter(function(item){
					if(item.grade.indexOf(Number(self.user.basic.level)) > -1){
						return item
					}
				})
			}
		}
		,methods: {
			none: function(){
				alert('준비중입니다')
			}
		}
	}
</script>

