<template>
	<div class="header"
	>
		<div class="logo">
			<router-link :to="{ name: 'dashboard'}">
				<div style="color: white; border: 2px solid #ddd; padding: 10px; margin-right: 50px;">{{ codes.metaInfo.title }}</div>
			</router-link>
		</div>
		<div class="greetings">
			<span class="id">{{ user.basic.userId }}</span>님 안녕하세요.
		</div>
		<div class="right">
			<div class="alert"><router-link :to="{ name: 'NoticeList' }"><i class="xi-bell-o"><span class="alert_mark active"></span></i></router-link></div>
			<div class="logout"><a @click="logout">로그아웃</a></div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Header'
		,props: ['user', 'program', 'callBack', 'codes']
		,data: function(){
			return {
				
			}
		}
		,methods: {
			logout: function(){
				if(confirm('로그아웃 하시겠습니까?')){
					sessionStorage.removeItem('TOKEN')
					this.$router.push({ name: 'AuthLogin'})
				}
			}
			,setUser: function(type){
				this.$emit('click', type)
			}
			,none: function(){
				alert('준비중입니다')
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log(call)
				}
			}
		}
	}
</script>









